import React from "react";
import { graphql } from "gatsby";

import Page from "../components/commons/Page";
import Hero from "../components/commons/Hero";
import Flex from "../components/commons/Flex";
import Image from "../components/commons/Image";
import BlocksRenderer from "../components/commons/BlocksRenderer";

const serializers = {
  signupBlock: ({ children }) => <div id="direct">{children}</div>,
};

export default function ContactUsPage({ data }) {
  const { sanityPage } = data;

  return (
    <Page
      title={sanityPage.seoTitle || sanityPage.title || sanityPage.heroTitle}
      description={sanityPage.seoDescription}
      pathname={sanityPage.slug.current}
    >
      <Hero>
        <Hero.Content>
          <Flex
            sx={{
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Hero.Title>{sanityPage.heroTitle || sanityPage.title}</Hero.Title>
            {sanityPage.subtitle && (
              <Hero.Subtitle>{sanityPage.subtitle}</Hero.Subtitle>
            )}
          </Flex>
        </Hero.Content>
        {sanityPage.heroImage && sanityPage.heroImage.asset && (
          <Hero.Illustration>
            <Image
              fluid={sanityPage.heroImage.asset.fluid}
              alt={sanityPage.heroImage.alt}
            />
          </Hero.Illustration>
        )}
      </Hero>
      <BlocksRenderer blocks={sanityPage.blocks} serializers={serializers} />
    </Page>
  );
}

export const pageQuery = graphql`
  query ContactUsPage {
    sanityPage(_id: { regex: "/(drafts.|)contactUs/" }) {
      title
      subtitle
      seoTitle
      seoDescription
      slug {
        current
      }
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      blocks {
        ...Blocks
      }
    }
  }
`;
