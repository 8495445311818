import React from "react";
import { getModule } from "gatsby";

export default function BlocksRenderer({
  blocks,
  serializers = {},
  ...otherProps
}) {
  return blocks.map((block) => {
    const Parent = serializers[block._type];
    const Component = getModule(block.component);

    if (!Component) {
      return <span>loading...</span>;
    }

    return Parent ? (
      <Parent {...block}>
        <Component {...block} />
      </Parent>
    ) : (
      <Component {...block} />
    );
  });
}
